.loginLeft img {
    max-width: 190px;
    position: static !important;
    height: auto !important;
}
.loginRight .form-group p {
    color: #fff;
    font-size: 12px;
    cursor: pointer;
}
.ExtraSign p {
    text-align: center;
    color: #fff;
    font-size: 15px;
}
.ExtraSign p a {
    color: #1ffe9d;
}
.loginLeft {
    background-image: url(../../../../assets/images/loginleft.png);
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;
}

.loginLeft h3 {
    color: #fff;
    font-size: 47px;
    font-weight: 600;
    max-width: 67%;
    margin: 30px 0 20px;
}

.loginLeft p {
    font-size: 19px;
    color: #fff;
    font-weight: 400;
    max-width: 430px;
}

.loginPage {
    margin: 0;
    background: #121314;
}

.loginRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 400px;
    margin: 0 auto;
}

.loginRight h4 {
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.loginRight h4 img {
    margin-left: 10px;
    max-width: 160px;
}

.loginRight .form-group label {
    font-size: 16px;
    width: 100%;
    color: #CBCBCB;
    margin-bottom: 3px;
}

.loginRight .form-group input[type="text"],
.loginRight .form-group input[type="password"],
.loginRight .form-group input[type="number"] {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 14px;
    color: #9A9AB0;
    outline: none;
}

.loginRight .form-group {
    margin-bottom: 18px;
    position: relative;
}

.loginRight .form-group span {
    position: absolute;
    right: 19px;
    top: 40px;
    filter: brightness(0) invert(1);
}

.loginRight .form-group input[type="checkbox"] {
    border: 1px solid #4F4F4F;
    background-color: #C9C9C9;

}

.loginRight .form-group label.remeber {
    font-size: 12px;
    color: #B8B8B8;
    width: auto;
}

.loginRight .form-group a {
    font-size: 15px;
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
}

.forgot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
}

.loginRight .form-group input[type="submit"] {
    background-image: linear-gradient(to right, #1C77BC, #03FE9D);
    background: #03FE9D;
    width: 100%;
    border: 0;
    border-radius: 10px;
    height: 56px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 19px;
    color: #000;
}

.loginRight .form-group input[type="checkbox"]:checked {
    border: 1px solid #1ffe9d !important;
    background-color: #1ffe9d !important;
}

.login span {
    margin: 0 auto;
    display: table;
    font-size: 13px;
    color: #C8C8C8;
    background: #121414;
    position: relative;
    z-index: 1;
    padding: 0 14px;
}

.login hr {
    margin: 0;
    opacity: 0.3;
    border-color: #DBDBDB;
    margin: -10px 0 0;
}

.login {
    padding: 5px 0 0;
}

.social ul {
    padding: 0;
    margin: 36px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social ul li {
    list-style: none;
    width: 31.33%;
    text-align: center;
    margin: 0 7px;
}

.social ul li a {
    border: 1px solid #565656;
    height: 44px;
    padding: 12px 0;
    width: 100%;
    display: table;
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
}

.social ul li a img {
    margin-right: 6px;
}

.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 4px;
}

.phoneNumber input {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 16px;
    color: #9A9AB0;
    outline: none;
}
.topLogin {
    position: absolute;
    top: 0;
}

.loginrelate {
    position: relative;
}

.topLogin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 26px 40px;
}
.loginrelate {
    position: relative;
}
.topLogin p {
    font-size: 14px;
    color: #C7C7C7CC;
    margin: 0;
}

.topLogin p a {
    color: #fff;
    font-weight: 700;
}

.topLogin span a {
    border: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 30px;
    margin-left: auto;
    float: right;
}
.topLogin span a:hover
{
    background: #1ffe9d;
    color: #000;
    border-color: #1ffe9d; 
}
.login-submit {
    background: #03fe9d;
    border: 0;
    border-radius: 10px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    margin-top: 19px;
    width: 100%;
    margin-left: 0%;
}
.otp
{
    background: transparent;
    color: #fff;
    padding: 0;
    font-size: 15px;
    border: 0;
    outline: none;
}
@media (max-width:1300px)
{
    .loginLeft{
        padding: 0 40px;
    }
}
@media (max-width:991px)
{
    .loginLeft h3 {
        color: #fff;
        font-size: 33px;
    }
    .loginLeft p {
        font-size: 17px;
    }
    .loginRight h4 {
        font-size: 32px;
    }
    .loginRight {
        padding: 0 19px;
    }
}
@media (max-width:991px)
{
    .loginLeft {
        padding: 40px 20px;
        /* height: auto; */
    }
    .loginLeft h3 {
        font-size: 29px;
    }
    .loginRight {
        padding: 20px 20px;
        /* height: auto; */
    }
    .loginRight h4 img {
        max-width: 127px;
    }
    .loginRight h4 {
        font-size: 28px;
    }
    .loginRight .form-group input[type="text"], .loginRight .form-group input[type="password"], .loginRight .form-group input[type="number"] {
  
        height: 49px;
    }
    .loginRight .form-group input[type="submit"] {
        height: 49px;
        font-size: 17px;
        margin-top: 3px;
    }
}

@media (max-width:767px)
{
    .loginLeft {
        height: auto;
    }
    
    .loginRight {
        height: auto;
    }
    .topLogin {
        position: static;
        padding: 16px 17px;
    }
    .topLogin p {
        margin: 0 0 11px;
    }
    .topLogin span a {
        float: left;
    }
}