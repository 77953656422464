/* Styles for the main loader container */
.mainLoader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgb(0 0 0 / 80%);
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainLoader  img {
    max-width: 130px;
}
/* Styles for the inner loader */
.innerLoader {
    text-align: center; /* Center the content horizontally */
}

/* Styles for the loader image */
.innerLoader img {
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
}