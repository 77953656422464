.pagination ul {
    padding: 0;
    margin: 12px 0 20px;
}
.pagination ul a {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    margin-right: 30px;
    position: relative;
}
.pagination ul a.active {
    opacity: 0.5;
}
.pagination ul a::before {
    width: 2px;
    height: 20px;
    background: #fff;
    content: "";
    position: absolute;
    left: -15px;
    top: 1px;
    transform: rotate(26deg);
}
.pagination ul a:first-child::before {
    opacity: 0;
}
.profileLeft,
.profileRight {
    background: #101111;
    padding: 22px;
    border-radius: 10px;
    height: 100%;
}

.profilelefttp,
.profileRight h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 25px;
    margin-bottom: 25px;
    color: #fff;
    font-size: 20px;
}

.profilelefttp h4 {
    font-size: 20px;
    color: #fff;
    margin: 0;
}
.Profileouter {
    padding: 27px 0 80px;
}
.profileImage {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 25px;
    margin-bottom: 25px;
    font-size: 20px;
}

.profileImage span img {
    max-width: 72px;
    max-height: 72px;
    border-radius: 100px;
    width: 72px;
    object-fit: cover;
    margin-right: 18px;
}

.profileData h6 {
    font-size: 19px;
    color: #fff;
    margin: 0;
}

.profileData label {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.profileData h5 {
    font-size: 15px;
    color: #898989;
    margin: 3px 0 0;
}

.profileLeft ul {
    padding: 0;
    margin: 0;
}

.profileLeft ul li {
    color: #fff;
    font-size: 14px;
    list-style: none;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
}

.profileLeft ul li span {
    width: 160px;
}

.profileRight .formGroup label {
    width: 100%;
    color: #fff;
    font-size: 16px;
}

.profileRight .formGroup input {
    background-color: transparent !important;
    border: 0;
    font-size: 14px;
    color: #898989 !important;
    width: 100%;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 8px;
    padding-top: 5px;
    background: transparent !important;
    outline: none;
}

.profileRight .formGroup {
    margin-bottom: 22px;
    position: relative;
}

.profileRight .formGroup .changePassword {
    position: absolute;
    right: 0;
    border: 1px solid #03FE9D;
    top: 8px;
    background: #000;
    color: #fff;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 14px;
}

.secure {
    background: #000000;
    padding: 20px 25px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.secure h6 {
    color: #fff;
    margin: 0;
}

.secure a {
    background: #03FE9D;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    padding: 9px 23px;
    font-weight: 600;
    border-radius: 10px;
}

.input {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 100px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 18px;
    color: #9A9AB0;
    outline: none;
}

.modal-content {
  background-color: #0D0D0D !important;
  padding: 40px 20px;
}

.modal-dialog {
  max-width: 600px;
}
.InputState {
    background-color: transparent !important;
    border: 0;
    font-size: 14px;
    color: #898989 !important;
    width: 100%;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 8px;
    padding-top: 5px;
    background: transparent !important;
    outline: none;
    height: 55px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.sidebar {
    width: 100%;
    position: sticky;
    top: 0;
}

.sidebar ul {
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    list-style: none;
}

.sidebar ul li a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 14px 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
}

.sidebar ul li a img {
    margin-right: 0px;
}

.sidebar ul li.active a {
    background: #03FE9D;
    color: #000;
    font-weight: 500;
}

.sidebar ul li a span {
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.top-logo {
    text-align: center;
    margin-bottom: 80px;
}

.logout {
    margin-top: 50px;
}
.ProfileLeft {
    background: #000;
    padding: 32px 20px;
    border-radius: 10px;
}
.userPack img {
    max-width: 72px;
    max-height: 72px;
    border-radius: 100px;
    object-fit: cover;
    margin: 0 auto 11px;
    display: table;
    min-width: 72px;
}
.userPack h5 {
    font-size: 19px;
    color: #fff;
    margin: 0;
    text-align: center;
}
.WallLeft h5 {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
}
.userPack h6 {
    font-size: 14px;
    color: #898989;
    text-align: center;
}
.WalletBal {
    background: #1E7EC3;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 23px 0 40px;
}
.WallLeft h4 {
    font-size: 17px;
    color: #fff;
    margin: 0;
}
.WalletBal span {
    font-size: 13px;
    background: #00FF9D;
    padding: 9px 12px;
    font-weight: 500;
    border-radius: 100px;
}
.ProfileRight {
    background: #101111;
    padding: 34px;
    border-radius: 10px;
}
.topDesc p {
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #dcdcdc4a;
    padding-bottom: 29px;
    margin-bottom: 39px;
}
.dashBox {
    background: #5487B714;
    border-radius: 10px;
    padding: 20px;
}
.topDash {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topDash span {
    background: #000;
    border-radius: 10px;
    padding: 4px;
}
.leftTopDas h5 {
    font-size: 19px;
    color: #fff;
    margin: 0;
}
.leftTopDas label {
    font-size: 12px;
    color: #B6B7BC;
    text-align: right;
    width: 100%;
}
.leftTopDas h5 img {
    margin-right: 6px;
}
.btnDash {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.rateDas p {
    color: #B6B7BC;
    font-size: 14px;
    margin: 12px 0 5px;
}
.rateDas h6 {
    font-size: 29px;
    color: #fff;
    margin: 0;
}
.btnDash span {
    font-size: 15px;
    background: #03FE9D;
    padding: 8px 13px;
    border-radius: 7px;
    font-weight: 500;
}
.addToEsim {
    cursor: pointer;
}