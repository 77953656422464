.ProfileLeft {
    background: #000;
    padding: 32px 20px;
    border-radius: 10px;
}

.userPack img {
    max-width: 72px;
    max-height: 72px;
    border-radius: 100px;
    object-fit: cover;
    margin: 0 auto 11px;
    display: table;
        min-height: 72px;
        min-width: 72px;
}

.userPack h5 {
    font-size: 19px;
    color: #fff;
    margin: 0;
    text-align: center;
}

.userPack h6 {
    font-size: 14px;
    color: #898989;
    text-align: center;
}


.WalletBal {
    background: #1E7EC3;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 23px 0 40px;
}

.WalletBal span {
    font-size: 13px;
    background: #00FF9D;
    padding: 9px 12px;
    font-weight: 500;
    border-radius: 100px;
}

.WallLeft h5 {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
}

.WallLeft h4 {
    font-size: 17px;
    color: #fff;
    margin: 0;
}

.sidebar {
    width: 100%;
    position: sticky;
    top: 0;
}

.sidebar ul {
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    list-style: none;
}

.sidebar ul li a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
}

.sidebar ul li a img {
    margin-right: 0px;
}

.sidebar ul li.active a {
    background: #03FE9D;
    color: #000;
    font-weight: 500;
}

.sidebar ul li a span {
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
@media (max-width:767px)
{
    .ProfileLeft
    {
        display: none;
    }
}