.appbtn,
.ouet-cart {
    align-items: center
}

body {
    background: #111 !important;
    margin: 0 auto;
    padding: 0;
    font-family: Poppins, sans-serif !important
}

select {
    appearance: none
}

a {
    text-decoration: none !important
}

li {
    list-style: none
}

input,
select {
    outline: 0 !important;
    box-shadow: none !important
}

::-webkit-scrollbar {
    width: 4px
}

::-webkit-scrollbar-track {
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    background: #00ff9d;
    border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
    background: #00ff9d
}

.newaboutbanner {
    position: relative;
    margin-bottom: 32px
}

.container.ipdad-cutm .col-md-3 .col-md-3,
.homepckCard .col-md-4,
.modal-header .modal-title.h4,
.newaboutbanner img,
.topHead .col-5 {
    width: 100%
}

.newaboutbanner h3 {
    position: absolute;
    top: 50%;
    padding: 0 110px
}

.css-1hb7zxy-IndicatorsContainer,
button.slick-arrow {
    display: none !important
}

.about-content ol li {
    list-style: square
}

.about-content p strong {
    color: #40fe9d;
    width: 100%;
    display: table
}

.aboutapp {
    background: #40fe9d;
    border-radius: 10px;
    padding: 60px 21px;
    text-align: center;
    margin-top: -211px;
    z-index: 1;
    position: relative
}

.phnappleft p {
    color: #fff;
    font-weight: 600;
    margin: 23px 0 0
}

.phnappright {
    text-align: right
}

.dataNotFound,
.modal-header,
.sentOTP {
    text-align: center
}

.phnappleft {
    padding-top: 111px
}

.appbtn {
    display: flex;
    justify-content: center;
    column-gap: 27px
}

.phnApp {
    padding-top: 47px;
    padding-bottom: 70px
}

.aboutapp h5 {
    font-size: 24px;
    max-width: 870px;
    margin: 0 auto 28px
}

.phnappleft img {
    max-width: 280px
}

.about-content ol {
    padding-left: 19px
}

.striplogo {
    margin-bottom: 15px
}

p.strip-desc {
    font-size: 13px;
    opacity: .7;
    margin: 0 0 10px
}

.PhoneInputCountry svg {
    fill: #fff;
    color: #fff
}

p.strip-desc a {
    color: #03fe9d;
    font-weight: 600;
    opacity: 1;
    padding-left: 6px
}

.faqPageQuestion h2.accordion-header button,
h2.accordion-header button {
    font-weight: 400;
    font-size: 20px;
    background: #1ffe9d !important;
    color: #000 !important
}

div#supportModal {
    background: #000000ba
}

.cardlogo {
    padding: 10px 0 30px
}

body .form-check-input:checked {
    background-color: #03fe9d;
    border-color: #03fe9d
}

.mainCoupon {
    padding: 40px 0 0
}

ul.slick-dots {
    max-width: 100%;
    right: 0;
    bottom: -45px
}

.modal-dialog {
    margin-top: 70px
}

.phoneIc .PhoneInputCountry {
    position: absolute;
    left: 16px;
    top: 19px
}

.ouet-cart {
    display: flex;
    justify-content: space-between;
    border: 1px solid #5c5c5c;
    border-radius: 10px;
    padding: 0 19px
}

body .phoneIc input {
    padding-left: 50px
}

.rightCrt {
    display: flex;
    align-items: center;
    column-gap: 12px
}

.modal-header h5,
.rightCrt span {
    color: #fff
}

.contactSelect::before {
    content: "";
    width: 9px;
    height: 9px;
    border: 2px solid #ddd;
    position: absolute;
    right: 10px;
    top: 14px;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
    pointer-events: none
}

ul.pacjage-count {
    margin: 16px 0 0;
    padding-left: 18px
}

.pacjage-count li a {
    color: #1ffe9d
}

ul.pacjage-count li {
    color: #fff;
    font-size: 14px;
    list-style: unset;
    opacity: .7
}

.contactSelect,
.homeSlids,
.phoneIc {
    position: relative
}

ul.slick-dots li {
    width: auto;
    margin: 0 5px;
    transition: .5s
}

div#changeplan .modal-dialog {
    max-width: 820px;
    border: 0;
    top: 60px
}

div#changeplan .modal-dialog .modal-content {
    background-color: transparent !important
}

.modal-header,
div#changeplan .modal-dialog .modal-header {
    border: 0
}

div#changeplan .modal-dialog .modal-header button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9
}

div#welcomemodal .modal-body.p-0 {
    border-radius: 0;
    padding: 50px 0 0 !important
}

.NavSlide .slick-list .slick-track {
    zoom: 1.2
}

.modal-head.welHead button {
    background-color: #fff;
    padding: 11px
}

.conitSlider .slick-slide.slick-active img {
    filter: blur(2px);
    -webkit-filter: blur(2px)
}

.conitSlider .slick-slide.slick-active.slick-center.slick-current img {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    position: relative;
    z-index: 9
}

.swal2-container button {
    background: #1ffe9d !important;
    color: #000 !important;
    box-shadow: none !important;
    border: 0 !important
}

.swal2-popup.swal2-modal {
    background: #000
}

div#supportModal .modal-dialog {
    top: 60px
}

.ForgetOtp div {
    column-gap: 16px
}

.swal2-popup.swal2-modal h2 {
    color: #fff;
    font-size: 23px;
    padding: 30px 0 10px
}

.ForgetOtp input {
    height: 59px;
    border-radius: 6px;
    border: 0
}

.modal-head.welHead {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 9
}

div#welcomemodal .modal-head.welHead {
    top: 70px
}

.dataNotFound {
    color: #fff
}

.css-1nmdiq5-menu,
.css-1nmdiq5-menu div {
    color: #fff !important
}

.row.margin-left-auto {
    margin-left: auto
}

.NavSlide .slick-list {
    max-width: 100%;
    padding: 0 !important;
    overflow: hidden
}

.homeSlids ul.slick-dots {
    max-width: 100%;
    bottom: 50px
}

.react-tel-input {
    margin-top: 7px
}

ul.slick-dots li button {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 10px;
    transition: .5s
}

ul.slick-dots li button::before {
    content: ""
}

.flag-dropdown {
    background: #1c2b35 !important;
    border-color: #1c2b35 !important;
    bottom: 1px !important
}

.bullets,
div#welcomemodal .modal-dialog {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto
}

.modal-body.p-0 {
    overflow: hidden;
    border-radius: 20px
}

.slick-slide.slick-active.slick-center.slick-current img {
    transform: scale(1.3);
    position: relative;
    top: -50px;
    transition: 1s
}

.slick-slide img {
    transition: 1s
}

ul.slick-dots li.slick-active button {
    background: #1ffe9d;
    width: 27px;
    transition: .5s
}

div#welcomemodal .modal-dialog {
    display: table;
    height: auto;
    position: absolute;
    max-width: 100%;
    transition: transform .7s ease-out;
    transform: translate(0, 381%)
}

div#welcomemodal.show .modal-dialog {
    transform: translateY(0) !important;
    transition: 1s !important;
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0 !important
}

div#welcomemodal {
    opacity: 0;
    visibility: hidden;
    transition: 1s !important;
    position: fixed;
    display: inherit !important;
    z-index: -1;
    transform: translateY(381%)
}

div#welcomemodal.show {
    opacity: 1;
    visibility: visible;
    transition: 1s !important;
    transform: translateY(0);
    z-index: 9;
    background: #000000b5
}

div#welcomemodal .modal-dialog .modal-content {
    border: 0;
    background: 0 0 !important
}

.privacyetxt {
    padding-bottom: 13px
}

.accordion-item {
    border: 0 !important
}

h2.accordion-header button.collapsed {
    background: #000 !important;
    border-radius: 0;
    padding: 22px 40px;
    color: #bfbfbf !important;
    font-size: 20px
}

h2.accordion-header button {
    box-shadow: none !important;
    padding: 22px 40px
}

.accordion-body {
    background: #000;
    padding: 20px 40px !important
}

.modal-header {
    justify-content: center;
    padding-bottom: 0
}

.accordion-body img {
    max-width: 330px;
    width: 100%;
    object-fit: contain
}

.modal-body {
    padding: 30px 33px !important
}

button.btn-close {
    background-color: #fff;
    opacity: 1;
    border-radius: 100px;
    background-size: 11px;
    padding: 0;
    width: 11px;
    height: 10px;
    cursor: pointer
}

.modal-content {
    background: #1a2228 !important
}

.MarginDiv .slick-slide:nth-child(2n) div:first-child div {
    background: #228cd2
}

.mobHeadings,
.special-label,
.topHead .col-7 {
    display: none
}

.bullets,
.bullets span {
    display: flex;
    position: absolute
}

.MarginDiv .slick-slide,
.partner-logo {
    padding: 0 8px
}

.faqPageQuestion h2.accordion-header button {
    box-shadow: none !important;
    padding: 26px 40px;
    border-bottom: 1px solid #4c4c4c33
}

.faqPageQuestion h2.accordion-header button.active {
    background: #1ffe9d !important;
    color: #000 !important;
    border-radius: 10px 10px 0 0
}

.faqPageQuestion h2.accordion-header button p {
    margin: 0
}

.faqPageQuestion {
    border-radius: 15px;
    overflow: hidden
}

.bullets span {
    width: 27px;
    height: 27px;
    background: #03fe9d;
    border-radius: 100px;
    transition: 4s
}

.bullets {
    height: 100%;
    top: 0;
    justify-content: center;
    max-width: 488px;
    transition: 3s
}

span.bulletone {
    top: 63px;
    left: 91px;
    transition: 4s
}

span.bullettwo {
    top: 293px;
    right: -105px;
    transition: 4s;
    background: #f9a020
}

span.bulletthree {
    background: #228cd2;
    transition: 4s;
    left: 40px;
    top: 417px
}

.dotsone,
.dotsthree,
.dotstwo {
    padding: auto
}

.slick-slide.slick-active.slick-current:nth-child(3) span.bulletone {
    left: 43px !important;
    top: 427px !important
}

.slick-slide.slick-active.slick-current:nth-child(3) span.bullettwo {
    top: 70px !important;
    right: 377px !important
}

.slick-slide.slick-active.slick-current:nth-child(3) span.bulletthree {
    top: 289px !important;
    left: 564px !important
}

.slick-slide.slick-active.slick-current:first-child span.bulletone {
    left: 566px !important;
    top: 293px !important
}

.slick-slide.slick-active.slick-current:first-child span.bullettwo {
    top: 417px !important;
    right: 421px !important
}

.slick-slide.slick-active.slick-current:first-child span.bulletthree {
    left: 93px !important;
    top: 61px !important
}

.slick-slide.slick-active.slick-current:nth-child(2) span.bulletone {
    left: 565px !important;
    top: 293px !important
}

.slick-slide.slick-active.slick-current:nth-child(2) span.bullettwo {
    top: 427px !important;
    right: 411px !important
}

.slick-slide.slick-active.slick-current:nth-child(2) span.bulletthree {
    left: 91px !important;
    top: 63px !important
}

body span.mediaLinks ul {
    left: 50%;
    transform: translateX(-50%);
    min-width: 210px
}

.css-1nmdiq5-menu {
    background: #000 !important;
    min-width: 130px
}

.css-1nmdiq5-menu div div,
.pointer {
    cursor: pointer
}

.partner-logo span {
    height: 135px;
    border: 1px solid #03fE9D4D;
    border-radius: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center
}

.partner-logo span img {
    max-width: 175px;
    object-fit: contain;
    max-height: 44px
}

@media (max-width:1400px) {
    .partner-logo span img {
        max-width: 125px;
        max-height: 36px
    }
}

@media (max-width:1200px) {
    .slick-slide.slick-active.slick-center.slick-current img {
        top: -42px
    }

    .slick-slide.slick-active.slick-current:first-child span.bullettwo {
        right: 461px !important
    }

    .slick-slide.slick-active.slick-current:first-child span.bulletone {
        left: 526px !important
    }

    .slick-slide.slick-active.slick-current:first-child span.bulletthree {
        left: 55px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(2) span.bullettwo {
        right: 455px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(2) span.bulletone {
        left: 524px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(2) span.bulletthree {
        left: 51px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(3) span.bulletthree {
        left: 524px !important;
        top: 289px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(3) span.bullettwo {
        right: 417px !important
    }
}

@media (max-width:1199px) {
    .ipdad-cutm .homepckCard .col-md-4 {
        width: 100%
    }

    .partner-logo span img {
        max-width: 127px;
        max-height: 28px
    }

    .partner-logo span {
        height: 95px
    }

    .container {
        max-width: 1150px !important
    }

    .ipdad-cutm .col-md-4 {
        width: 40%
    }

    .ipdad-cutm .col-md-8 {
        width: 60%
    }

    h3 {
        font-size: 40px
    }
}

@media (max-width:991px) {

    .ipdad-cutm .col-md-4,
    .phnappright img {
        width: 100%
    }

    .newaboutbanner h3 {
        padding: 0 50px
    }

    .ipdad-cutm .col-md-8 {
        width: 100%;
        padding-top: 30px
    }

    .about-content p,
    .phnappleft p {
        font-size: 14px
    }

    .about-content p strong {
        font-size: 18px
    }

    .aboutapp {
        padding: 37px 21px;
        margin-top: -161px
    }

    .phnappleft {
        padding-top: 51px
    }

    .aboutapp h5 {
        font-size: 19px;
        max-width: 680px
    }

    .phnApp {
        padding-top: 17px;
        padding-bottom: 40px
    }

    .phnappleft img {
        max-width: 160px
    }

    .homeSlids {
        height: auto
    }

    .homeSlids ul.slick-dots {
        bottom: 12px
    }

    .slick-slide.slick-active.slick-current:first-child span.bullettwo {
        right: 389px !important;
        top: 347px !important
    }

    .slick-slide.slick-active.slick-current:first-child span.bulletone {
        left: 410px !important;
        top: 183px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(2) span.bullettwo {
        right: 405px !important;
        top: 327px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(2) span.bulletone {
        left: 408px !important;
        top: 183px !important
    }

    .slick-slide.slick-active.slick-current:nth-child(3) span.bulletthree {
        top: 189px !important;
        left: 410px !important
    }

    .container {
        max-width: 100% !important
    }

    .row.margin-left-auto {
        padding-left: 133px
    }

    .mobPalans .col-md-4,
    .udadEsin .col-md-4 {
        width: 50%
    }

    .NavSlide {
        padding: 20px 50px
    }

    button.navbar-toggler {
        position: absolute;
        right: 0;
        top: 2px;
        background: #1ffe9d;
        padding: 3px
    }

    body h3 {
        font-size: 35px
    }

    footer .col-md-2 {
        width: 23.666667%
    }

    footer .col-md-3 {
        width: 29%
    }
}

@media (max-width:767px) {

    .about-content p strong,
    .aboutapp h5 {
        font-size: 16px
    }

    .mobBk,
    .mobilePurchase {
        background-repeat: no-repeat
    }

    .rightmobpur ul,
    header {
        right: 0;
        position: absolute
    }

    .mobUsername,
    .navback,
    .newmobileSidebar ul li,
    .outreHomeside,
    .rightmobpur {
        position: relative
    }

    a.navbar-brand img {
        max-width: 140px
    }

    .newaboutbanner h3 {
        padding: 0 15px;
        transform: translateY(-50%)
    }

    .phnappright {
        text-align: center;
        padding-top: 27px
    }

    .aboutapp h5 {
        max-width: 680px;
        margin: 0 auto 22px
    }

    .ouet-cart {
        padding: 12px 14px 20px;
        flex-wrap: wrap;
        row-gap: 17px
    }

    .aboutapp,
    h2.accordion-header button.collapsed {
        padding: 22px 21px
    }

    .Toastify__toast-body div {
        font-size: 14px
    }

    .mobSpan span,
    .rightmobFiletr span {
        font-size: 12px;
        color: #5f7989;
        text-align: center;
        display: inline-block
    }

    .blog-sidebar {
        padding: 0
    }

    .blog-sidebar .tags a {
        margin: 4px;
        padding: 8px;
        font-size: 11px
    }

    .leftmobFiletr {
        width: 87%
    }

    .rightmobFiletr {
        width: 13%
    }

    .tabs-section .row {
        padding: 5px
    }

    .mobileFilters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 17px
    }

    .post-item a {
        gap: 2px
    }

    .post-details {
        padding: 0 15px
    }

    .helpdeskMoob,
    .mobilePurchase {
        padding: 24px 18px;
        display: flex;
        max-width: 98%
    }

    .leftmobFiletr .slick-slide {
        margin: 0 3px
    }

    .footer ul {
        margin: 0
    }

    .leftmobFiletr .slick-track {
        display: flex
    }

    .mobSpan span.active {
        border-color: #2eaef6;
        color: #2eaef6
    }

    .homeheader button.navbar-toggler span,
    header.homeheader .mobUsername {
        color: #000
    }

    .mobSpan span {
        border: 1px solid #5f7989;
        border-radius: 5px;
        padding: 8px 12px;
        width: 100%
    }

    .rightmobFiletr span {
        border: 1px solid #5f7989;
        border-radius: 5px;
        padding: 8px 9px;
        width: auto;
        margin-left: auto;
        float: right
    }

    .mobilePurchase {
        align-items: center;
        background-image: url("./assets/images/installbk.png");
        background-size: 100% 100%;
        justify-content: space-between;
        margin: 0 auto
    }

    .helpdeskMoob {
        background-image: linear-gradient(to right, #2188c7, #00ff9d);
        align-items: center;
        border-radius: 10px;
        justify-content: space-between;
        margin: 15px auto 0
    }

    body .helpdeskMoob h6 {
        font-size: 14px;
        display: block !important;
        font-weight: 400;
        text-align: left;
        max-width: 150px;
        margin: 0
    }

    .helpdeskMoob h5 {
        color: #fff;
        font-size: 17px;
        margin: 0 0 2px
    }

    .leftdeskmob a {
        background: #1ffe9d;
        color: #000;
        font-size: 14px;
        padding: 8px 13px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 15px
    }

    .deviceSuport {
        padding: 20px 5px 0
    }

    .deviceSuport h5 {
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        margin: 0 0 3px
    }

    .mobileSims {
        padding: 0 5px
    }

    .mobileSims h4 {
        color: #fff;
        font-size: 16px;
        margin: 21px 0 14px
    }

    body .deviceSuport h6 {
        font-size: 14px;
        color: #fff;
        display: block;
        text-align: left;
        margin: 0;
        opacity: .5;
        font-weight: 400
    }

    .deviceSuport .formGroup input {
        width: 100%;
        height: 43px;
        background: #000;
        border: 0;
        border-radius: 100px;
        padding: 0 22px
    }

    .rightmobpur ul {
        background: #000;
        top: 45px;
        min-width: 232px;
        border-radius: 10px;
        padding: 11px
    }

    .deviceSuport .formGroup button {
        background: #1d7abf;
        color: #fff;
        border: 0;
        padding: 9px 22px;
        border-radius: 100px;
        margin-left: 13px
    }

    .deviceSuport .formGroup {
        background: #1c2b35;
        padding: 20px;
        border-radius: 10px;
        margin: 15px 0 0;
        display: flex;
        align-items: center
    }

    .rightmobpur a {
        background: #1ffe9d;
        color: #000;
        font-size: 14px;
        padding: 8px 13px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        float: right;
        width: 100%;
        margin: 4px 0
    }

    body .mobHeadings {
        display: block
    }

    .leftMobPur h5 {
        font-size: 15px;
        color: #fff;
        max-width: 165px;
        margin: 0
    }

    .mobHeadings h5 a {
        font-size: 13px;
        color: #fff;
        font-weight: 400
    }

    .mobHeadings h5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        margin: 2px 0 15px
    }

    .mobUsername h4 {
        font-size: 16px;
        margin: 0
    }

    .lefttopSideHead,
    .mobile-sims .col-md-2,
    .righttopSideHead {
        width: 50%
    }

    .mobUsername h5 {
        font-size: 10px;
        margin: 0
    }

    .mobBk {
        background-image: url("./assets/images/mobheader.png");
        background-size: cover;
        padding: 90px 0
    }

    header {
        top: 43px;
        left: 0
    }

    button.navbar-toggler span {
        color: #fff;
        background-image: none;
        position: relative;
        top: 2px
    }

    .mobUsername {
        padding-left: 40px;
        padding-top: 0;
        color: #fff;
        z-index: 9
    }

    .homeSlids,
    .topHaed .col-7,
    a.navbar-brand,
    br {
        display: none
    }

    button.navbar-toggler {
        left: 0;
        background: 0 0;
        right: inherit;
        border: 0;
        padding: 0;
        z-index: 99;
        outline: 0 !important;
        box-shadow: none !important
    }

    .mobPalans .col-md-4,
    .topHaed .col-5,
    .udadEsin .col-md-4,
    footer .col-md-2,
    footer .col-md-3 {
        width: 100%
    }

    .lefttopSideHead {
        display: flex;
        align-items: center;
        column-gap: 10px
    }

    .lefttopSideHead h5 {
        color: #fff;
        margin: 0;
        font-size: 18px
    }

    .lefttopSideHead h6,
    .righttopSideHead h6 {
        font-size: 12px;
        opacity: .7;
        margin: 4px 0 0;
        font-weight: 400
    }

    .righttopSideHead h6 {
        text-align: right
    }

    .righttopSideHead h5 {
        text-align: right;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 7px;
        font-size: 17px;
        margin: 0
    }

    .topSideHead {
        display: flex;
        align-items: center;
        margin-bottom: 21px
    }

    div#changeplan .modal-dialog .modal-header button {
        right: 14px;
        top: 16px
    }

    div#changeplan .modal-dialog .modal-content {
        margin: 0;
        border: 0
    }

    div#changeplan .modal-body {
        border-radius: 0
    }

    div#changeplan.show {
        justify-content: flex-end;
        align-items: flex-end
    }

    .navback,
    .waletBalance {
        align-items: center;
        display: flex
    }

    div#supportModal .modal-dialog {
        top: 13px;
        bottom: 0;
        position: absolute;
        margin: 0 auto;
        transform: translate(0);
        max-width: 96%;
        display: table;
        left: 0;
        right: 0
    }

    div#supportModal .modal-header button.btn-close {
        top: 25px;
        right: 17px;
        position: absolute;
        z-index: 9;
        cursor: pointer
    }

    div#changeplan {
        background: #00000091
    }

    div#changeplan .modal-dialog {
        max-width: 100%;
        border: 0;
        top: auto;
        bottom: 0;
        margin: 0;
        position: absolute
    }

    .navback {
        padding-bottom: 23px;
        justify-content: center;
        color: #fff;
        min-height: 82px
    }

    .navback span {
        position: absolute;
        left: 0;
        top: 0
    }

    div#navbarSupportedContent {
        position: fixed;
        top: 0;
        background: #000;
        z-index: 99;
        left: -12px;
        right: 0;
        padding-bottom: 14px;
        min-width: 106.5%;
        height: 100vh;
        overflow-y: auto
    }

    .newmobileSidebar {
        padding: 24px
    }

    .waletBalance {
        background-image: linear-gradient(to right, #1e7ec3, #2eaff7);
        border-radius: 10px;
        padding: 25px 20px 20px;
        justify-content: space-between
    }

    .newmobileSidebar ul li,
    .referEarn {
        background: #061620;
        border-radius: 10px;
        margin-top: 15px
    }

    .referEarn h6 {
        font-size: 12px;
        line-height: 1.3;
        padding-right: 20px;
        opacity: .6;
        margin: 0
    }

    .referEarn {
        padding: 20px;
        display: flex;
        align-items: center;
        column-gap: 14px
    }

    .newmobileSidebar ul li {
        padding: 16px 20px
    }

    .newmobileSidebar ul li a {
        width: 100%;
        color: #fff;
        opacity: .8;
        font-size: 14px;
        display: flex;
        align-items: center;
        column-gap: 12px
    }

    .newmobileSidebar ul li::before {
        content: "";
        position: absolute;
        right: 21px;
        width: 9px;
        height: 9px;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(-45deg);
        opacity: .7;
        top: 23px
    }

    .referEarn h5 {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        margin: 0 0 3px
    }

    .waletBalance h6 {
        opacity: .7;
        font-size: 14px;
        font-weight: 400
    }

    .waletBalance a {
        background: #00ff9d;
        color: #000;
        padding: 8px 15px;
        border-radius: 100px
    }

    .waletBalance span {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        column-gap: 9px
    }

    .homeSlids {
        height: auto
    }

    .NavSlide .slick-list .slick-track {
        zoom: 1.4;
        left: 1px
    }

    .NavSlide {
        padding: 10px 0
    }

    .slick-slide.slick-active.slick-center.slick-current img {
        top: -15px;
        transform: scale(1.2)
    }

    .row.margin-left-auto,
    .row.margin-left-auto .col-md-6 {
        padding-left: 0
    }

    h2.accordion-header button {
        padding: 14px 20px;
        font-size: 16px
    }

    div#welcomemodal .modal-head.welHead {
        top: 61px;
        left: 13px
    }

    ul.slick-dots {
        max-width: 100%;
        right: 0;
        bottom: -45px
    }

    body h3 {
        font-size: 27px
    }
}

.error {
    font-size: 13px;
    margin-left: 5px;
    margin-top: 4px;
    color: red
}

.error-message {
    color: red;
    font-size: 12px
}

.rating span.fa.fa-star {
    color: #ddd;
    font-size: 22px;
    margin-right: 5px
}

.rating span.fa.fa-star.checked {
    color: #ffc000
}

.CusotmArow button.slick-arrow {
    display: block !important;
    position: absolute;
    left: -9px;
    background: #82838b61;
    border: 1px solid #82838b;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    content: "";
    z-index: 9
}

.CusotmArow button.slick-arrow::before {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #82838b;
    display: flex;
    border-top: 0;
    border-left: 0;
    transform: rotate(130deg);
    top: 12px;
    left: 14px;
    position: absolute
}

.CusotmArow button.slick-arrow.slick-next {
    left: inherit;
    right: -12px;
    transform: rotate(180deg);
    top: 42%
}

.fesrureSlider .slick-slide {
    padding: 0 9px
}

/* More intense colors with different direction */
.ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-content .ant-skeleton-paragraph>li {
    background-image: linear-gradient(90deg, rgb(195 190 190 / 23%) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%) !important;
}

.ant-skeleton-header .ant-skeleton-avatar {
    background-image: linear-gradient(90deg, rgb(195 190 190 / 23%) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%) !important;
}